<template>
  <v-card id="account-setting-card">
    <div id="Music" cols="12" sm="12" style="padding: 30px">
      <h1>Brightness</h1>
      <br />
      <v-slider v-model="obj.brightness" color="red darken-4" min="1" max="100" label="Custom Brightness"></v-slider
      ><br />
      <center style="font-size: 40px">
        <br />
        <v-btn color="success" class="mr-4" @click="saveBrightness()">Save & Play</v-btn>
      </center>
      <v-snackbar v-model="snackbar" :timeout="timeout" :right="x === 'right'" :top="y === 'top'" :color="color">
        {{ text }}
        <v-btn color="blue" text @click="snackbar = false">Close</v-btn>
      </v-snackbar>
    </div>
  </v-card>
</template>
<style scoped>
</style>
<script>
// import { appConfig } from "../../firebaseConfig";
import { doc, setDoc } from 'firebase/firestore'
import { dbRef, brightnessClick } from '../../../../firebaseConfig'
import { getUID, getCurrentDeviceSelected } from '../../../../localStorage'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'Brightness',
  data() {
    return {
      length: 5,
      valid: false,
      search: '',
      musicUrl: '',
      musicRules: [v => !!v || 'Music URL is required', v => /\.(?:wav|mp3)$/i.test(v) || 'Music must be valid'],
      selected: null,
      notificationValues: ['Warriyo', 'LostSky', 'Jasico', 'Electro'],
      myitems: [],
      latitude: '',
      longitude: '',
      obj: {
        appVibration: {
          seconds: '',
          vibration: '',
        },
        battryPercentage: '',
        isCharging: '',
        bluetooth: '',
        brightness: '',
        flashlight: '',
        mobileData: '',
        mobileDataType: '',
        musicUrl: {
          music: '',
          url: '',
        },
        sound: '',
      },
      color: '',
      mode: '',
      snackbar: false,
      text: "Hello, I'm a snackbar",
      timeout: 6000,
      x: null,
      y: 'top',
    }
  },
  mounted: function () {
    brightnessClick()
  },
  methods: {
    async saveBrightness() {
      this.$store.commit('LOADER', true)
      let deviceID = getCurrentDeviceSelected()
      let uid = getUID()
      let uuids = uuidv4()
      console.log(this.selected)
      const docData = {
        actionType: 'brightness',
        createdDate: new Date().toDateString(),
        createdTS: new Date().getTime(),
        deviceId: deviceID,
        docId: uuids,
        uid: uid,
        updateDate: new Date().toDateString(),
        updatedTs: new Date().getTime(),
        status: 'pending',
        special: {
          value: this.obj.brightness,
        },
      }
      console.log(docData)
      await setDoc(doc(dbRef, 'action', uuids), docData)
        .then(res => {
          console.log(res)
          this.text = 'successfully Changes'
          this.snackbar = true
          this.color = 'success'
        })
        .catch(error => {
          console.log(error)
          this.text = 'Something went wrong.'
          this.snackbar = true
          this.color = 'failed'
        })
        .finally(res => {
          this.$store.commit('LOADER', false)
        })
    },
  },
}
</script>